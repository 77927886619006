import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Input, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslocoPipe } from '@jsverse/transloco';
import { DialogService } from '@ngneat/dialog';
import { filter, take } from 'rxjs';
import { UiKitBaseComponent } from '../../../core/ui-kit/components/ui-kit-base/ui-kit-base.component';
import { PermissionDirective } from '../../../features/authentication/directives/permission.directive';
import { RoleDirective } from '../../../features/authentication/directives/role.directive';
import { Role } from '../../../features/authentication/enums/role.enum';
import { AuthenticationService } from '../../../features/authentication/services/authentication.service';
import { UserProfileDialogComponent } from '../user-profile-dialog/user-profile-dialog.component';

@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [NgIf, AsyncPipe, TranslocoPipe, RoleDirective, PermissionDirective],
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent extends UiKitBaseComponent {
  private readonly authService = inject(AuthenticationService);
  private readonly dialogService = inject(DialogService);

  @Input() small?: boolean;

  user$ = this.authService.user$.pipe(filter(Boolean), take(1));

  userSignal = toSignal(this.user$);

  isPlatformManager = computed(() => this.userSignal()?.tenant === 'all');

  tenantLabel = computed(() => 'common.tenant-name.' + this.userSignal()?.tenant);

  Role = Role;

  openProfileDialog(): void {
    const dialogRef = this.dialogService.open(UserProfileDialogComponent, {
      width: '60vw',
      data: {},
    });
  }
}
