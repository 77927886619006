import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { Toast } from 'ngx-toastr';

@Component({
  selector: 'app-ui-kit-toast',
  standalone: true,
  imports: [NgIf],
  templateUrl: './ui-kit-toast.component.html',
  styles: [
    `
      :host {
        display: block;
        position: relative;
        overflow: hidden;
        margin: 0 0 6px;
        padding: 16px 24px !important;
        background-image: unset !important;
        border-radius: 8px !important;
        pointer-events: all;
        cursor: pointer;
      }
    `,
  ],
})
export class UiKitToastComponent extends Toast {
  classes = '';

  ngOnInit() {
    const classesMap = {
      'toast-info': 'text-dark-purple-700 dark:text-dark-purple-200',
      'toast-success': 'text-green-700 dark:text-green-200',
      'toast-warning': 'text-amber-800 dark:text-amber-400',
      'toast-error': 'text-red-800 dark:text-red-400',
    };
    const type = this.toastClasses.split(' ')[0];

    this.classes = classesMap[type as keyof typeof classesMap];
  }
}
