import { ALL_ROLES, Role } from '../../../../features/authentication/enums/role.enum';
import { SidebarMenuItem } from '../models/sidebar-menu-item.model';

export const MOBILE_MENU: SidebarMenuItem[] = [
  {
    pageId: 'op__chat',
    link: '/chat',
    icon: 'mdi-message',
    title: 'sidebar.menu.chat',
    roles: [Role.ADMIN, Role.OPERATOR],
  },
  {
    pageId: 'op__dashboard',
    link: '/dashboard',
    icon: 'mdi-view-dashboard',
    title: 'sidebar.menu.dashboard',
    roles: [...ALL_ROLES],
  },
  {
    pageId: 'op__repository',
    link: '/repositories',
    icon: 'mdi-folder-multiple',
    title: 'sidebar.menu.repositories',
    roles: [Role.ADMIN, Role.OPERATOR],
  },
  {
    pageId: 'op__operator',
    link: '/operators',
    icon: 'mdi-account-multiple',
    title: 'sidebar.menu.operators',
    roles: [Role.ADMIN],
  },
  {
    pageId: 'pm__tenants',
    link: '/tenants',
    icon: 'mdi-domain',
    title: 'sidebar.menu.tenants',
    roles: [Role.PLATFORM_MANAGER],
  },
  {
    pageId: 'op__agents',
    link: '/agents',
    icon: 'mdi-puzzle',
    title: 'sidebar.menu.agents',
    roles: [Role.ADMIN, Role.OPERATOR],
  },
  {
    pageId: 'pm__agents',
    link: '/agents/management',
    icon: 'mdi-puzzle',
    title: 'sidebar.menu.agents',
    roles: [Role.PLATFORM_MANAGER],
  },
  {
    pageId: 'pm__license',
    link: '/licenses/templates',
    icon: 'mdi-license',
    title: 'sidebar.menu.licenses',
    roles: [Role.PLATFORM_MANAGER],
  },
  // {
  //   pageId: 'pm_prompt_library',
  //   link: '/prompt-library',
  //   icon: 'mdi-xml',
  //   title: 'sidebar.menu.prompt-library',
  //   roles: [Role.PLATFORM_MANAGER],
  // },
  {
    pageId: 'pm_context_library',
    link: '/context-library',
    icon: 'mdi-file-cog',
    title: 'sidebar.menu.context-library',
    roles: [Role.PLATFORM_MANAGER],
  },
  // {
  //   pageId: 'op__license',
  //   link: '/licenses/plans',
  //   icon: 'mdi-license',
  //   title: 'sidebar.menu.licenses',
  //   roles: [Role.ADMIN],
  // },
  // {
  //   pageId: 'op__support',
  //   icon: 'mdi-lifebuoy',
  //   title: 'sidebar.menu.support',
  //   roles: [Role.ADMIN],
  // },
];
