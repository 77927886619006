import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, filter, finalize, mergeMap, Observable, take, throwError } from 'rxjs';
import {
  AuthenticationService,
  SKIP_REFRESH_TOKEN,
} from '../../features/authentication/services/authentication.service';
import { Nullable } from '../../shared/types/nullable.type';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private readonly authenticationService = inject(AuthenticationService);
  private readonly router = inject(Router);

  private isRefreshing = false;
  private refreshTokenSubject = new BehaviorSubject<Nullable<string>>(null);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const REFRESH_TOKEN_STATUS = 401;

    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !req.context.has(SKIP_REFRESH_TOKEN) &&
          error.status === REFRESH_TOKEN_STATUS
        ) {
          return this.handle401Error(req, next);
        }

        return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authenticationService.refreshToken().pipe(
        mergeMap(() => {
          this.refreshTokenSubject.next(new Date().toISOString());

          return next.handle(request);
        }),
        catchError((error) => {
          this.router.navigate(['/login']);

          return throwError(() => error);
        }),
        finalize(() => (this.isRefreshing = false))
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        mergeMap(() => next.handle(request))
      );
    }
  }
}
