<div class="w-full" [style.display]="state().value === 'inactive' ? 'none' : ''">
  <div class="flex items-center justify-between">
    <div class="w-full text-sm font-medium mr-4">
      <div *ngIf="title" [class]="classes" class="font-bold text-lg block" [attr.aria-label]="title">
        {{ title }}
      </div>
      <div *ngIf="message && options.enableHtml" role="alert" [class]="classes" [innerHTML]="message"></div>
      <div *ngIf="message && !options.enableHtml" role="alert" [class]="classes" [attr.aria-label]="message">
        {{ message }}
      </div>
    </div>

    <div class="w-12" *ngIf="options.closeButton">
      <button
        type="button"
        class="w-10 h-10 relaive bg-transparent hover:bg-white/50 dark:hover:bg-white/50 rounded-full flex items-center justify-center transition-colors ease-in-out duration-300"
        (click)="remove()"
      >
        <span class="text-dark-purple-500 dark:text-white text-lg mdi mdi-close"></span>
        <span class="sr-only">Close</span>
      </button>
    </div>
  </div>
</div>
