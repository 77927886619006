import { inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';
import { Observable, switchMap, takeWhile, tap, timer } from 'rxjs';
import { BundleSummaryStatus } from '../enums/bundle-summary-status.enum';
import { BundleOrganicSummary } from '../models/bundle-organic-summary.model';
import { BundleSummary } from '../models/bundle-summary.model';
import { SummaryService } from './summary.service';

@Injectable({
  providedIn: 'root',
})
export class SummaryNotificationService {
  private readonly summaryService = inject(SummaryService);
  private readonly toastrService = inject(ToastrService);
  private readonly translocoService = inject(TranslocoService);

  pollSummaryById(id: string): Observable<BundleSummary> {
    return timer(0, 10000).pipe(
      switchMap(() => this.summaryService.getSummaryByBundleId(id, true)),
      tap((summary) => {
        if (summary.status === BundleSummaryStatus.GENERATED) {
          this.toastrService.success(
            this.translocoService.translate('summary.notification.description', {
              bundleName: summary.bundleName,
              bundleId: summary.bundleId,
              departmentId: summary.departmentId,
              vertical: summary.vertical,
            }),
            this.translocoService.translate('summary.notification.title')
          );
        } else if (summary.status === BundleSummaryStatus.NOT_GENERATED_YET) {
          this.toastrService.error(
            this.translocoService.translate('summary.notification.error.description', {
              bundleName: summary.bundleName,
              bundleId: summary.bundleId,
              departmentId: summary.departmentId,
              vertical: summary.vertical,
            }),
            this.translocoService.translate('common.attention')
          );
        }
      }),
      takeWhile((summary) => summary.status === BundleSummaryStatus.GENERATING, true)
    );
  }

  pollOrganicSummaryById(id: string): Observable<BundleOrganicSummary> {
    return timer(0, 10000).pipe(
      switchMap(() => this.summaryService.getOrganicSummaryByBundleId(id, true)),
      tap((summary) => {
        if (summary.status === BundleSummaryStatus.GENERATED) {
          this.toastrService.success(
            this.translocoService.translate('summary.organic.notification.description', {
              bundleName: summary.bundleName,
              bundleId: summary.bundleId,
              departmentId: summary.departmentId,
            }),
            this.translocoService.translate('summary.notification.title')
          );
        } else if (summary.status === BundleSummaryStatus.NOT_GENERATED_YET) {
          this.toastrService.error(
            this.translocoService.translate('summary.organic.notification.error.description', {
              bundleName: summary.bundleName,
              bundleId: summary.bundleId,
              departmentId: summary.departmentId,
            }),
            this.translocoService.translate('common.attention')
          );
        }
      }),
      takeWhile((summary) => summary.status === BundleSummaryStatus.GENERATING, true)
    );
  }
}
