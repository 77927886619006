import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";

import { Store } from "@ngrx/store";
import { catchError, filter, map, of, switchMap, tap } from "rxjs";
import { AlertService } from "../../../../core/ui-kit/services/alert.service";
import { BundleService } from "../service/bundle.service";
import { loadBundleActions, loadBundleDocumentPreviewActions } from "./bundle.actions";
import { selectSelectedBundleLoaded } from "./bundle.selectors";

@Injectable()
export class BundleEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly bundleService = inject(BundleService);
  private readonly alertService = inject(AlertService);

  loadBaseBundles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBundleActions.loadBaseBundles),
      switchMap(({ page, departmentId, query, infiniteScroll, vertical }) =>
        this.bundleService
          .getBaseBundles(page, undefined, departmentId, query, vertical)
          .pipe(map((bundles) => loadBundleActions.loadBaseBundlesSuccess({ bundles, infiniteScroll })))
      )
    )
  );

  loadVerticalBundles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBundleActions.loadVerticalBundles),
      switchMap(({ page, departmentId, query, infiniteScroll, vertical }) =>
        this.bundleService
          .getVerticalBundles(page, undefined, vertical, departmentId, query)
          .pipe(map((bundles) => loadBundleActions.loadVerticalBundlesSuccess({ bundles, infiniteScroll })))
      )
    )
  );

  deleteBundleByID$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBundleActions.deleteBundle),
      switchMap(({ id, departmentId, vertical }) =>
        this.bundleService
          .deleteBundleById(id)
          .pipe(map(() => loadBundleActions.loadBaseBundles({ departmentId, vertical })))
      )
    )
  );

  createBundle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBundleActions.createBundle),
      switchMap(({ payload, departmentId }) =>
        this.bundleService.createBundle(payload).pipe(
          tap(() => this.alertService.showOperationCompletedAlert()),
          map(() => loadBundleActions.loadBaseBundles({ departmentId }))
        )
      )
    )
  );

  editBundle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBundleActions.editBundle),
      switchMap(({ payload, bundleId, departmentId, vertical }) =>
        this.bundleService.editBundle(payload, bundleId).pipe(
          tap(() => this.alertService.showOperationCompletedAlert()),
          map(() => loadBundleActions.loadBaseBundles({ departmentId, vertical }))
        )
      )
    )
  );

  loadDocumentPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBundleDocumentPreviewActions.loadDocumentPreview),
      switchMap(({ bundleId, document }) =>
        this.bundleService
          .getDocumentPreviewUrl(bundleId, document.id)
          .pipe(
            map((previewResponse) =>
              loadBundleDocumentPreviewActions.loadDocumentPreviewFromSignedURL({ previewResponse })
            )
          )
      )
    )
  );

  loadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBundleDocumentPreviewActions.loadDocument),
      switchMap(({ bundleId, documentId }) =>
        this.bundleService
          .getDocumentPreviewUrl(bundleId, documentId)
          .pipe(map((singleDocument) => loadBundleDocumentPreviewActions.loadDocumentSuccess({ singleDocument })))
      )
    )
  );

  loadDocumentPreviewSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBundleDocumentPreviewActions.loadDocumentPreviewFromSignedURL),
      switchMap(({ previewResponse }) => {
        const fileMetadata = previewResponse.fileMetadata;

        const bundleId = fileMetadata['x-goog-meta-bundleId'];
        const fileId = fileMetadata['x-goog-meta-fileId'];
        const tenantId = fileMetadata['x-goog-meta-tenantId'];
        const fileName = fileMetadata['x-goog-meta-fileName'];

        return this.bundleService
          .getFilePreviewFromSignedUrl(previewResponse.link, bundleId, fileId, tenantId, fileName)
          .pipe(map((document) => loadBundleDocumentPreviewActions.loadDocumentPreviewSuccess({ document })));
      })
    )
  );

  updateDocumentDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadBundleActions.updateDocumentDetails),
        switchMap(({ bundleId, documentId, payload }) =>
          this.bundleService.updateDocumentDetails(bundleId, documentId, payload).pipe(
            tap(() => {
              this.bundleService.getBundleDocumentsById(bundleId, undefined, undefined, undefined, false).subscribe();
            })
          )
        ),
        tap(() => {
          this.alertService.showOperationCompletedAlert();
        })
      ),
    { dispatch: false }
  );

  getBundleByID$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBundleActions.getBundleByID),
      concatLatestFrom(() => [this.store.select(selectSelectedBundleLoaded)]),
      filter(([_, loaded]) => !loaded),
      switchMap(([{ id }]) =>
        this.bundleService.getBundleById(id).pipe(
          map((bundle) => loadBundleActions.getBundleByIDSuccess({ bundle })),
          catchError(() => of(loadBundleActions.getBundleByIDError()))
        )
      )
    )
  );
}
