import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, filter, groupBy, map, mergeMap, of, switchMap, take, tap } from 'rxjs';
import { AlertService } from '../../../../core/ui-kit/services/alert.service';
import { Nullable } from '../../../../shared/types/nullable.type';
import { BundleSummaryStatus } from '../enums/bundle-summary-status.enum';
import { BundleOrganicSummary } from '../models/bundle-organic-summary.model';
import { SummaryNotificationService } from '../service/summary-notification.service';
import { SummaryService } from '../service/summary.service';
import { loadSummaryActions } from './summary.actions';

@Injectable()
export class SummaryEffects {
  private readonly actions$ = inject(Actions);
  private readonly summaryService = inject(SummaryService);
  private readonly summaryNotificationService = inject(SummaryNotificationService);
  private readonly alertService = inject(AlertService);

  loadSummaryByID$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSummaryActions.loadSummaryByID),
      mergeMap(({ id }) =>
        this.summaryService.getSummaryByBundleId(id).pipe(
          map((summary) => loadSummaryActions.loadSummaryByIDSuccess({ summary })),
          catchError((error) => {
            if (error.status === 400 && error.error.message === 'NOT_FOUND') {
              return of(loadSummaryActions.loadSummaryByIDSuccess({ summary: null }));
            }

            return of(
              loadSummaryActions.loadSummaryByIDFailure({
                summaryError: error.error.message,
              })
            );
          })
        )
      )
    )
  );

  pollSummaryByID$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSummaryActions.loadSummaryByIDSuccess),
      filter(({ summary }) => summary?.status === BundleSummaryStatus.GENERATING),
      groupBy(({ summary }) => summary!.bundleId),
      mergeMap((group$) =>
        group$.pipe(
          take(1),
          switchMap(({ summary }) =>
            this.summaryNotificationService.pollSummaryById(summary!.bundleId).pipe(
              map((finalSummary) => loadSummaryActions.loadSummaryByIDSuccess({ summary: finalSummary })),
              catchError((error) => {
                if (error.status === 400 && error.error.message === 'NOT_FOUND') {
                  return of(loadSummaryActions.loadSummaryByIDSuccess({ summary: null }));
                }
                return of(loadSummaryActions.loadSummaryByIDFailure({ summaryError: error.error.message }));
              })
            )
          )
        )
      )
    )
  );

  loadOrganicSummaryByID$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSummaryActions.loadOrganicSummaryByID),
      switchMap(({ id }) =>
        this.summaryService.getOrganicSummaryByBundleId(id).pipe(
          map((summary: Nullable<BundleOrganicSummary>) =>
            loadSummaryActions.loadOrganicSummaryByIDSuccess({
              summary,
            })
          )
        )
      )
    )
  );

  pollOrganicSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSummaryActions.loadOrganicSummaryByIDSuccess),
      filter(({ summary }) => summary?.status === BundleSummaryStatus.GENERATING),
      groupBy(({ summary }) => summary!.bundleId),
      mergeMap((group$) =>
        group$.pipe(
          take(1),
          switchMap(({ summary }) =>
            this.summaryNotificationService
              .pollOrganicSummaryById(summary!.bundleId)
              .pipe(map((finalSummary) => loadSummaryActions.loadOrganicSummaryByIDSuccess({ summary: finalSummary })))
          )
        )
      )
    )
  );

  uploadSummaryCSV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSummaryActions.uploadSummaryCSV),
      switchMap(({ id, file }) =>
        this.summaryService.uploadSummaryCsv(id, file).pipe(
          map(() =>
            loadSummaryActions.loadSummaryByID({
              id,
            })
          )
        )
      )
    )
  );

  validateFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSummaryActions.validateSummaryFields),
      switchMap(({ id, fields }) =>
        this.summaryService.validateFields(id, fields).pipe(
          map(() =>
            loadSummaryActions.loadSummaryByID({
              id,
            })
          )
        )
      )
    )
  );

  invalidateFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSummaryActions.invalidateSummaryFields),
      switchMap(({ id, fields }) =>
        this.summaryService.invalidateFields(id, fields).pipe(
          map(() =>
            loadSummaryActions.loadSummaryByID({
              id,
            })
          )
        )
      )
    )
  );

  deleteSummaryByID$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSummaryActions.deleteSummaryByID),
      switchMap(({ id }) =>
        this.summaryService.deleteSummaryById(id).pipe(
          map(() =>
            loadSummaryActions.loadSummaryByID({
              id,
            })
          )
        )
      )
    )
  );

  editContentAnswer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSummaryActions.editContentAnswer),
      switchMap(({ id, fieldId, manualAnswer }) =>
        this.summaryService.setUserAnswer(id, fieldId, manualAnswer).pipe(
          map(() =>
            loadSummaryActions.loadSummaryByID({
              id,
            })
          )
        )
      )
    )
  );

  generateSummaryByID$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSummaryActions.generateSummaryByID),
      switchMap(({ id }) =>
        this.summaryService.generateSummaryById(id).pipe(
          map(() =>
            loadSummaryActions.loadSummaryByID({
              id,
            })
          )
        )
      )
    )
  );

  sendToCrm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadSummaryActions.sendToCrm),
        switchMap(({ id }) =>
          this.summaryService.sendToCrm(id).pipe(tap(() => this.alertService.showOperationCompletedAlert()))
        )
      ),
    { dispatch: false }
  );
}
