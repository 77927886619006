import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, tap } from "rxjs";

import { Router } from "@angular/router";
import { Paginate } from "../../../models/pagination.model";
import { Repository } from "../models/repository.model";
import { RepositoryService } from "../service/repository.service";
import { loadRepositoryActions } from "./repository.actions";

@Injectable()
export class RepositoryEffects {
  private readonly actions$ = inject(Actions);
  private readonly router = inject(Router);
  private readonly repositoryService = inject(RepositoryService);

  loadRepositories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRepositoryActions.loadRepositories),
      switchMap(({ page, infiniteScroll, counts, query, vertical }) =>
        this.repositoryService.getRepositories(page, undefined, counts, query, vertical).pipe(
          map((repositories: Paginate<Repository>) =>
            loadRepositoryActions.loadRepositoriesSuccess({
              repositories,
              infiniteScroll,
            })
          )
        )
      )
    )
  );

  loadRepositoryById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRepositoryActions.loadRepositoryByID),
      switchMap(({ id }) =>
        this.repositoryService.getRepositoryById(id).pipe(
          map((repository: Repository) =>
            loadRepositoryActions.loadRepositoryByIDSuccess({
              repository,
            })
          )
        )
      )
    )
  );

  deleteRepositoryById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRepositoryActions.deleteRepositoryByID),
      switchMap(({ id, vertical }) =>
        this.repositoryService
          .deleteRepositoryById(id)
          .pipe(map(() => loadRepositoryActions.loadRepositories({ counts: true, vertical })))
      )
    )
  );

  createRepository$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadRepositoryActions.createRepository),
        switchMap(({ payload }) =>
          this.repositoryService.createRepository(payload).pipe(
            tap((repository) => {
              this.router.navigate(['/repositories', repository.id]);
            })
          )
        )
      ),
    { dispatch: false }
  );

  loadVerticalRepositories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRepositoryActions.loadVerticalRepositories),
      switchMap(({ page, infiniteScroll, counts, query, vertical }) =>
        this.repositoryService.getVerticalRepositories(vertical, page, undefined, counts, query).pipe(
          map((repositories: Paginate<Repository>) =>
            loadRepositoryActions.loadVerticalRepositoriesSuccess({
              repositories,
              infiniteScroll,
            })
          )
        )
      )
    )
  );
}
