<aside
  class="h-screen flex flex-col justify-between content-between relative"
  aria-label="Sidebar"
  [class.collapsed]="isCollapsed()"
>
  <!-- Expanded Sidebar -->
  <div
    class="h-full pl-2 pt-2 pb-2 overflow-y-auto bg-white dark:bg-black flex flex-col justify-between"
    id="sidebar-expanded"
    [class.hidden]="isCollapsed()"
  >
    <div>
      <div class="mb-14 p-4 relative">
        <a href="/post-login">
          <img class="py-3 logo-sm" src="/assets/images/logo/klens_logo.svg" alt="KLens" />
        </a>
      </div>

      <!-- Collapse Button -->
      <button
        class="absolute top-8 -right-6 w-8 h-8 rounded-full bg-white hover:bg-dark-purple-200 ease-in-out duration-300"
        id="btn-sidebar-collpased"
        (click)="toggleSidebar()"
      >
        <div class="flex items-center leading-none min-w-0 justify-center gap-xs">
          <span class="mdi mdi-arrow-collapse-left"></span>
        </div>
      </button>

      <div class="p-3 mb-4">
        <button
          *authRoles="[Role.ADMIN, Role.OPERATOR]"
          type="button"
          (click)="openNewChatDialog()"
          class="text-white bg-dark-purple-500 hover:bg-dark-purple-700 transition-colors ease-in-out duration-150 focus:ring-3 focus:outline-none focus:ring-dark-purple-200 dark:focus:ring-dark-purple-800 font-medium rounded-full text-sm tracking-wide px-4 py-2 text-center text-nowrap"
        >
          <span class="mdi mdi-plus"></span> {{ 'sidebar.actions.new-chat' | transloco }}
        </button>
      </div>

      <ul class="mb-6">
        @for (menuItem of desktopItems; track $index) {
        <li
          class="nav-item px-4 py-2 mb-1 rounded-md border-dark-purple-200 hover:border-coral-500 transition ease-in-out hover:bg-dark-purple-50 dark:hover:bg-dark-purple-800 duration-300"
          [attr.data-page]="menuItem.pageId"
          *authRoles="menuItem.roles"
        >
          <a
            [routerLink]="menuItem.link"
            [routerLinkActive]="'active'"
            [title]="menuItem.title | transloco"
            class="flex items-center text-dark-purple-500 dark:text-white hover:text-dark-purple-900 dark:hover:text-dark-purple-200"
          >
            <span class="text-lg mdi mr-3" [ngClass]="menuItem.icon"></span>{{ menuItem.title | transloco }}
          </a>
        </li>
        }

        <ng-container *authRoles="[Role.OPERATOR]">
          <li class="flex items-center px-4 py-4 text-xs font-semibold uppercase text-dark-purple-400">
            <span>{{ 'sidebar.menu.agents' | transloco }}</span>
            <div class="flex-grow border-t border-dark-purple-300-300 ml-2"></div>
          </li>

          @for (agent of agents(); track $index) {
          <li
            class="nav-item px-4 py-2 rounded-md border-dark-purple-200 hover:border-coral-500 transition ease-in-out hover:bg-dark-purple-50 dark:hover:bg-dark-purple-800 duration-300"
          >
            <a
              [routerLink]="['/agents', agent.id, 'repositories']"
              [routerLinkActive]="'active'"
              [title]="agent.title | transloco"
              class="flex items-center text-dark-purple-500 dark:text-white hover:text-dark-purple-900 dark:hover:text-dark-purple-200"
            >
              <span class="text-lg mdi mdi-puzzle mr-3"></span>{{ agent.title | transloco }}
            </a>
          </li>
          }
        </ng-container>
      </ul>
    </div>

    <!-- User profile -->
    <app-user-profile></app-user-profile>
  </div>

  <!-- Collapsed Sidebar -->
  <div
    class="h-full p-2 pr-0 overflow-y-auto bg-white flex flex-col justify-between dark:bg-black"
    [class.hidden]="!isCollapsed()"
    id="sidebar-collapsed"
  >
    <div class="flex flex-col items-center">
      <button
        class="m-6 w-8 h-8 rounded-full bg-dark-purple-100 hover:bg-dark-purple-200"
        id="btn-sidebar-expand"
        (click)="toggleSidebar()"
      >
        <div class="flex items-center leading-none min-w-0 justify-center gap-xs">
          <span class="mdi mdi-arrow-collapse-right"></span>
        </div>
      </button>

      <a href="/post-login" class="mb-4">
        <img class="py-3" width="34" src="/assets/images/logo/klens_icon.svg" alt="KLens" />
      </a>

      <div class="mb-4 p-3">
        <button
          *authRoles="[Role.ADMIN, Role.OPERATOR]"
          class="w-8 h-8 rounded-full text-white bg-dark-purple-500 hover:bg-dark-purple-700 transition-colors ease-in-out duration-300 focus:ring-3 focus:outline-none focus:ring-dark-purple-200 dark:focus:ring-dark-purple-800"
          id="btn-sidebar-expand"
          type="button"
          (click)="openNewChatDialog()"
        >
          <div class="flex items-center leading-none min-w-0 justify-center gap-xs">
            <span class="mdi mdi-plus"></span>
          </div>
        </button>
      </div>

      <ul class="mb-6 flex flex-col items-center">
        @for (menuItem of mobileItems; track $index) {
        <li
          class="nav-item px-4 py-2 mb-1 rounded-md border-dark-purple-200 hover:border-coral-500 transition ease-in-out hover:bg-dark-purple-50 dark:hover:bg-dark-purple-800 duration-300"
          [attr.data-page]="menuItem.pageId"
          *authRoles="menuItem.roles"
        >
          <a
            [routerLink]="menuItem.link"
            [routerLinkActive]="'active'"
            [title]="menuItem.title | transloco"
            class="flex items-center text-dark-purple-500 dark:text-white hover:text-dark-purple-900 dark:hover:text-dark-purple-200"
          >
            <span class="text-lg mdi" [ngClass]="menuItem.icon"></span>
          </a>
        </li>
        }
      </ul>
    </div>

    <!-- User profile -->
    <app-user-profile [small]="true"></app-user-profile>
  </div>

  <!-- Mobile Sidebar -->
</aside>
